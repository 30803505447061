import React, { useState } from "react"
import { Link } from "gatsby"
import Media from "react-media"

import styled from "styled-components"

import authStore from "../stores/auth"

import Footer from "./Footer"
import { LogoDiv } from "./LandingBodyHelpers"

import closeLogo from "../imgs/close.png"
import burgerLogo from "../imgs/burge-menu.png"

import { SigninButton } from "../components/LandingBodyHelpers"

const Container = styled.div`
  background-color: #f8faff;
`

const Div1 = styled.div`
  background-size: 100% 100%;
  background-repeat: repeat-x;
  display: flex;
  flex-flow: column;
`

const NavDiv = styled.div`
  z-index: 10;
  display: flex;
  align-items: center;
  padding: 24px 10vw;
  flex: 1;
`

const NavLinksDiv = styled.div`
  margin-left: auto;
  display: inline-flex;
  flex-flow: row;
  align-items: center;
`

const NavLink = styled(Link)`
  color: ${props => (props.active ? "#667af4" : "#3c3c3c")};
  text-decoration: none;
  margin-right: 40px;
`

export const Header = styled.h1`
  margin-top: 100px;
  margin-bottom: 74px;

  text-align: center;
  font-weight: 700;
  font-size: 36px;
  line-height: 120%;

  text-align: center;

  color: #667af4;
`
const NavHeader = styled.div`
  z-index: 10;
  display: flex;
  flex-flow: row;
  padding: 10px 20px 0;
`
const BurgerImg = styled.img`
  height: 40px;
  min-height: 40px;
  ${props => props.dark && `filter: invert(70%);`}
`
const NavContainer = styled.div`
  z-index: 10;
  position: absolute;
  top: 0;
  left: 0;
  width: 98%;
  margin: 1%;
  background: white;
  display: ${props => (props.isHidden ? "none" : "flex")};
  flex-flow: column;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04),
    0px 16px 24px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  padding-bottom: 14px;
`
const NavClose = styled.a`
  display: flex;
  flex-flow: row;
  padding-right: 10px;
  padding-top: 5px;
`
const FlexGrow1Span = styled.span`
  flex-grow: 1;
`
const NavCloseImg = styled.img`
  height: 40px;
  min-height: 40px;
`
const MobileNavLink = styled(Link)`
  text-decoration: none;
  color: blue;
  padding: 10px;
`
export const ExpandingNav = ({ dark }) => {
  const [isHidden, setIsHidden] = useState(true)
  function toggleIsHidden() {
    setIsHidden(!isHidden)
  }
  const isLoggedin = authStore.isAuthenticated
  const handleClick = e => {
    e.preventDefault()
    if (isLoggedin) {
      return authStore.logout()
    }
    authStore.login()
  }

  const homepageUrl =
    process.env.GATSBY_ACTIVE_ENV !== "production"
      ? "https://staging.mountproxies.com/"
      : "https://mountproxies.com/"

  return (
    <React.Fragment>
      <NavHeader>
        <LogoDiv dark={dark}></LogoDiv>
        <FlexGrow1Span></FlexGrow1Span>
        <BurgerImg dark={dark} src={burgerLogo} onClick={toggleIsHidden} />
      </NavHeader>
      <NavContainer isHidden={isHidden}>
        <NavClose href="#" onClick={toggleIsHidden}>
          <FlexGrow1Span></FlexGrow1Span>
          <NavCloseImg src={closeLogo} alt="close-nav"></NavCloseImg>
        </NavClose>
        <MobileNavLink to={homepageUrl}>Home</MobileNavLink>
        <MobileNavLink to="/products">Products</MobileNavLink>
        <SigninButton
          onClick={handleClick}
          backgroundColor="blue"
          color="white"
        >
          {isLoggedin ? "Sign out" : "Sign In"}
        </SigninButton>
      </NavContainer>
    </React.Fragment>
  )
}

export const NavBar = ({ dark }) => {
  const isLoggedin = authStore.isAuthenticated
  const handleClick = e => {
    e.preventDefault()
    if (isLoggedin) {
      return authStore.logout()
    }
    authStore.login()
  }

  return (
    <Div1>
      <NavDiv>
        <LogoDiv dark={dark} />
        <NavLinksDiv>
          <NavLink to="/products">Products</NavLink>
          <NavLink as="a" href="#" onClick={handleClick}>
            {isLoggedin ? "Sign out" : "Sign In"}
          </NavLink>
        </NavLinksDiv>
      </NavDiv>
    </Div1>
  )
}

export default function PublicLayout({ darkLogo, title, children }) {
  return (
    <Media
      queries={{
        small: "(max-width: 960px)",
      }}
    >
      {matches => {
        const isMobile = matches.small

        return (
          <Container>
            {isMobile ? (
              <ExpandingNav dark={darkLogo} />
            ) : (
              <NavBar dark={darkLogo} isMobile={isMobile} />
            )}
            <Header>{title}</Header>
            {children}
            <Footer isMobile={isMobile} />
          </Container>
        )
      }}
    </Media>
  )
}
