import React from "react"
import styled from "styled-components"

import {
  DesktopDiv5,
  DesktopDiv5flex,
  DesktopDiv5left,
  LogoDiv,
  DesktopDiv5right,
  DesktopPagesCol,
  DesktopSocialCol,
  DesktopSocialAnchor,
  DesktopSocialImg,
  DesktopDiv5hr,
  DesktopDiv5copyrights,
  DesktopNavLink,
} from "./LandingBodyHelpers"

import facebookLogo from "../imgs/facebook-light.png"
import instagramLogo from "../imgs/instagram-light.png"
import twitterLogo from "../imgs/twitter-light.png"

const PlainLink = styled.a`
  text-decoration: none;
  color: inherit;
`

const Container = styled(DesktopDiv5)`
  background-color: ${props => props.bgColor || "#9ba1aa"};
`

const InstaLogo = styled(DesktopSocialImg)`
  filter: invert(0) grayscale(1);
`

const LinksContainer = styled(DesktopDiv5right)`
  box-sizing: border-box;
  @media (max-width: 960px) {
    width: 50%;
  }
`

const Socials = () => (
  <DesktopPagesCol>
    <p>FOLLOW US</p>
    <DesktopSocialCol>
      <DesktopSocialAnchor
        href="https://www.facebook.com/Rockproxies-110410253783157"
        target="_blank"
      >
        <DesktopSocialImg src={facebookLogo}></DesktopSocialImg>
      </DesktopSocialAnchor>
      <DesktopSocialAnchor
        href="https://www.instagram.com/rockproxies/"
        target="_blank"
      >
        <InstaLogo src={instagramLogo} />
      </DesktopSocialAnchor>
      <DesktopSocialAnchor
        href="https://twitter.com/RockProxies"
        target="_blank"
      >
        <DesktopSocialImg src={twitterLogo}></DesktopSocialImg>
      </DesktopSocialAnchor>
    </DesktopSocialCol>
  </DesktopPagesCol>
)

const Footer = ({ isMobile, bgColor }) => {
  const copyrightMsg = `© ${new Date().getFullYear()} MountProxies. All rights reserved.`

  const blogUrl =
    process.env.GATSBY_ACTIVE_ENV !== "production"
      ? "https://staging.mountproxies.com/blog"
      : "https://mountproxies.com/blog"

  return (
    <Container bgColor={bgColor}>
      <DesktopDiv5flex>
        {!isMobile && (
          <DesktopDiv5left>
            <LogoDiv />
          </DesktopDiv5left>
        )}
        <LinksContainer>
          <DesktopPagesCol>
            <p>
              <PlainLink target="_blank" href={blogUrl}>
                Blog
              </PlainLink>
            </p>
            <p>
              <DesktopNavLink to="/termsofservice">Terms</DesktopNavLink>
            </p>
            {isMobile && (
              <p>
                <DesktopNavLink to="/contact-us">Contact Us</DesktopNavLink>
              </p>
            )}
          </DesktopPagesCol>
          {!isMobile && (
            <DesktopPagesCol>
              <p>
                <DesktopNavLink to="/contact-us">Contact Us</DesktopNavLink>
              </p>
            </DesktopPagesCol>
          )}
          {!isMobile && <Socials />}
        </LinksContainer>
        {isMobile && (
          <LinksContainer>
            <Socials />
          </LinksContainer>
        )}
      </DesktopDiv5flex>
      <DesktopDiv5hr></DesktopDiv5hr>
      <DesktopDiv5copyrights>{copyrightMsg}</DesktopDiv5copyrights>
    </Container>
  )
}

export default Footer
