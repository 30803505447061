import React from "react"
import styled from "styled-components"

import { Header } from "../components/PublicLayout"
import withLayout from "../components/layout"

import emailImg from "../imgs/email-purple.svg"

const ContactBody = styled.div`
  @media screen and (min-width: 769px) {
    padding-left: 50px;
    min-height: 50vh;
    max-width: 250px;
  }

  @media screen and (max-width: 768px) {
    padding: 0 20px;
  }

  margin: 0 auto;
  float: none;
`

const Title = styled.h3`
  font-weight: 700;
  font-size: 16px;
  line-height: 120%;
  margin-bottom: 10px;
  margin-top: 0;
`

const Text = styled.p`
  font-weight: normal;
  font-size: 16px;
  line-height: 120%;
  margin: 0;

  a {
    color: #9ba1aa;
    text-decoration: none;
  }
`

const InfoContainer = styled.div`
  margin-top: 34px;
  display: flex;
  align-items: flex-start;
`

const Icon = styled.img`
  margin-right: 20px;
`

function ContactsIndex() {
  return (
    <div>
      <Header>Contact Us</Header>
      <ContactBody>
        <InfoContainer>
          <Icon src={emailImg} />
          <div>
            <Title>E-MAIL</Title>
            <Text>
              <a href="mailto:team@mountproxies.com">team@mountproxies.com</a>
            </Text>
          </div>
        </InfoContainer>
      </ContactBody>
    </div>
  )
}

export default withLayout(ContactsIndex)
